import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import {
    Box,
    SpaceBetween,
    Table,
    TextFilter,
    Header,
    CollectionPreferences,
    Pagination
} from "@amzn/awsui-components-react/polaris";

import { useCollection } from "@amzn/awsui-collection-hooks";
import Constants from "../../../mango/js/utils/Constants";
import ConstantsLightHouse from "../utils/Constants";
import LighthouseHelperFunctions from "../../../lighthouse/js/common/HelperFunctions";
import HelperFunctions from "../../../mango/js/common/HelperFunctions";
import {
    LinkServiceButton,
    LinkServiceMultiSelect,
    LinkServiceSelect,
    LinkServiceToggle
} from "../../../mango/js/components/CommonComponents";
import IspCutsheetTableData from "./IspCutsheetTableData";
import IspDashboardHelper from "./IspDashboardHelper";

export const Preferences = ({
    preferences,
    setPreferences,
    contentDisplayPreference,
    entity,
    disabled
}) => (
    <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        disabled={disabled}
        preferences={preferences}
        onConfirm={({ detail }) => {
            const isDxView = IspDashboardHelper.isDxViewToggled(entity);

            if (isDxView) {
                // Do not save preferences for DX View
                return;
            }

            if (detail.custom) {
                setPreferences({
                    ...detail,
                    contentDisplay: detail.contentDisplay.map(c => ({
                        id: c.id,
                        visible: !ConstantsLightHouse.DEFAULT_HIDDEN_COLUMNS.includes(c.id)
                    })),
                    ...ConstantsLightHouse.DEFAULT_ISP_TABLE_PREFERENCES
                });
            } else {
                setPreferences(detail);
            }

            HelperFunctions.setHiddenColumnsCookie(
                entity,
                (detail.contentDisplay || [])
                    .filter(c => !c.visible)
                    .map(c => c.id)
                    .join(",")
            );
        }}
        pageSizePreference={{
            title: "Page size",
            options: [
                { value: 10, label: "10 Items" },
                { value: 25, label: "25 Items" },
                { value: 50, label: "50 Items" },
                { value: 100, label: "100 Items" },
                { value: 250, label: "250 Items" }
            ]
        }}
        wrapLinesPreference={{
            label: "Wrap lines",
            description: "Check to see all the text and wrap the lines"
        }}
        contentDisplayPreference={contentDisplayPreference}
        stickyColumnsPreference={{
            firstColumns: {
                title: "Stick first column(s)",
                description:
                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                options: [
                    { label: "None", value: 0 },
                    { label: "First column", value: 1 },
                    { label: "First two columns", value: 2 },
                    { label: "First three columns", value: 3 }
                ]
            },
            lastColumns: {
                title: "Stick last column(s)",
                description:
                    "Keep the last column visible while horizontally scrolling the table content.",
                options: [
                    { label: "None", value: 0 },
                    { label: "Last column", value: 1 },
                    { label: "Last two columns", value: 2 }
                ]
            }
        }}
        customPreference={(customValue, setCustomValue) => (
            <div>
                <p>Reset Preferences</p>
                <LinkServiceToggle
                    checked={customValue}
                    onChange={({ detail }) => setCustomValue(detail.checked)}
                />
            </div>
        )}
    />
);

const pageLabel = pageNumber => `Page ${pageNumber} of all pages`;

export const createCutsheetVersionOptions = (currentCutsheetVersion, latestCutsheetVersion) => {
    const cutsheetVersionOptions = [];

    const versionDocumentMapToVersionOptions = (cutsheetVersionToDocumentVersionMap) => {
        Object.entries(cutsheetVersionToDocumentVersionMap).forEach((entry) => {
            cutsheetVersionOptions.push({
                label: `V${entry[0]}`,
                value: entry[0]
            });
        });
    };

    if (latestCutsheetVersion) {
        versionDocumentMapToVersionOptions(latestCutsheetVersion.cutsheetVersionToDocumentVersionMap);
    } else {
        versionDocumentMapToVersionOptions(currentCutsheetVersion.cutsheetVersionToDocumentVersionMap);
    }

    return cutsheetVersionOptions;
};

const IspCutsheetTableV2 = forwardRef((props, parentRef) => {
    const [preferences, setPreferences] = useState(() => {
        const isDxView = IspDashboardHelper.isDxViewToggled(props.cutsheetType);
        return {
            ...IspDashboardHelper.getTablePreferences(props.cutsheetType, isDxView),
            contentDisplay: []
        };
    });
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [filteringText, setFilteringText] = useState("");
    const [selectedLiuOptions, setSelectedLiuOptions] = React.useState([]);
    const [selectedAdvancedSortOptions, setSelectedAdvancedSortOptions] = React.useState([]);
    const [sortingColumn, setSortingColumn] = useState(props.updatedColumnDefinitions[0]);
    const [sortingDescending, setSortingDescending] = useState(false);
    // Determine the identifier property based on cutsheetType
    const [identifierProperty, setIdentifierProperty] = useState(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.CLIENT);

    const sortedTableItems = useMemo(() => IspDashboardHelper.sortByColumn(
        props.items,
        sortingColumn ? sortingColumn.id : "",
        sortingDescending
    ),
    [sortingColumn, sortingDescending, props.items]);

    const contentDisplayPreference = {
        title: "Column preferences",
        options: props.updatedColumnDefinitions.map(c => ({
            id: c.id,
            label: c.header
        }))
    };

    const { items, collectionProps, paginationProps } = useCollection(
        sortedTableItems,
        {
            filtering: {
                filteringFunction: item => Object.keys(item)
                    .some(key => item[key] && item[key].includes(filteringText))
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: { sortingColumn },
            selection: {},
            stickyColumns: preferences.stickyColumns
        }
    );

    // These two functions are invoked when we have the two events that trigger table sorting. The first sorting action
    // is the click of a table header on the table which performs the typical sorting. The second sorting action
    // is the selection of an advanced sort option
    const onSortingChange = (e) => {
        setSortingColumn(e.detail.sortingColumn);
        setSortingDescending(e.detail.isDescending);
    };
    const handleAdvancedSortChange = (selectedSortingOption) => {
        setSortingColumn(selectedSortingOption);
    };

    const getIdPortAValue = column => column.id_port_a;

    const handleSubmitEdit = async (item, column, newValue) => {
        // if the column being submitted is a Patch Panel A column
        // then call the backend to search for the patchpanels
        // this getIPPMInformation will make a network call
        if (props.hopOptionsDropDown.some(hop => hop.id_pp_a === column.id)) {
            if (newValue.trim()) {
                await props.getIPPMInformation(newValue);
            }
            props.handlePatchPanelAInputChangeForLiu(item, column, newValue.trim(), selectedItems);
        } else if (props.hopOptionsDropDown.some(hop => hop.id_port_a === column.id
            || hop.id_port_z === column.id)) {
            // for ISP Port input we need a special handler which does a special massupdate
            props.handlePortAInputChangeForLiu(item, column, newValue.trim(), selectedItems);
        } else if (column.id === ConstantsLightHouse.ISP_ATTRIBUTES.a_osp_panel_ports
            || column.id === ConstantsLightHouse.ISP_ATTRIBUTES.z_osp_panel_ports) {
            // mass update may need to be used for updating osp ports
            props.handleOspPortUpdates(item, column, newValue.trim(), selectedItems);
        } else if (column.id === ConstantsLightHouse.ISP_ATTRIBUTES.z_interface) {
            props.handleZInterfaceUpdates(item, column, newValue.trim(), selectedItems);
        } else {
            // call backend to update individual attributes
            props.handleOtherFieldUpdates(item, column, newValue.trim(), selectedItems);
        }
    };

    useImperativeHandle(parentRef, () => ({
        getSelectedRows: () => selectedItems,
        handleOnSubmitTableCell: handleSubmitEdit
    }));

    useEffect(() => {
        switch (props.cutsheetType) {
        case ConstantsLightHouse.ISP_CUTSHEET_TYPE.LINE:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.LINE);
            break;
        case ConstantsLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.INTERNAL_CABLING);
            break;
        default:
            setIdentifierProperty(ConstantsLightHouse.ISP_TABLE_PRIMARY_KEYS.CLIENT);
        }
    });

    useEffect(() => {
        // Loop through IspCutsheetTableData.hopOptionsDropDown and check if any row in sortedTableItems
        // has the corresponding key/value
        const requiredSelectedOptions = props.hopOptionsDropDown
            .filter((column) => {
                const idPortAValue = getIdPortAValue(column);
                return sortedTableItems.some(item => item[idPortAValue]);
            });

        // Combine requiredSelectedOptions with existing selectedOptions
        const updatedSelectedLiuOptions = [...selectedLiuOptions, ...requiredSelectedOptions];

        // Update the state with the modified selected options
        setSelectedLiuOptions(updatedSelectedLiuOptions);
    }, [sortedTableItems, props.hopOptionsDropDown]); // Run the effect whenever sortedTableItems changes

    useEffect(() => {
        const isDxView = IspDashboardHelper.isDxViewToggled(props.cutsheetType);

        // Get content display settings based on view type
        const newContentDisplay = isDxView
            ? props.updatedColumnDefinitions.map(c => ({
                id: c.id,
                visible: !ConstantsLightHouse.DX_HIDDEN_COLUMNS.includes(c.id)
            }))
            : IspDashboardHelper.updateColumnVisibility(
                props.updatedColumnDefinitions,
                props.cutsheetType
            ).map(c => ({
                id: c.id,
                visible: c.visible
            }));

        setPreferences(prevPreferences => ({
            ...prevPreferences,
            ...IspDashboardHelper.getTablePreferences(props.cutsheetType, isDxView),
            contentDisplay: newContentDisplay
        }));
    }, [props.updatedColumnDefinitions]);

    const generateTableHeaders = (propsArg) => {
        const cutsheetHeaders = [
            ...propsArg.currentCutsheetVersion &&
                <SpaceBetween size="xxs" direction="horizontal">
                    <Box variant="h3" fontWeight="bold" padding={{ top: "xs" }}>
                        Cutsheet Version
                    </Box>
                    <LinkServiceSelect
                        selectedOption={({
                            label: `V${propsArg.currentCutsheetVersion.cutsheetVersion}`,
                            value: propsArg.currentCutsheetVersion.cutsheetVersion
                        })}
                        onChange={propsArg.handleCutsheetVersionChange}
                        options={createCutsheetVersionOptions(
                            propsArg.currentCutsheetVersion, propsArg.latestCutsheetVersion
                        )}
                        disabled={propsArg.cutsheetVersionSelectDisabled}
                    />
                </SpaceBetween>
        ];
        const nonCutsheetHeaders = [
            ...propsArg.cutsheetType === ConstantsLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING && (
                <LinkServiceToggle
                    onChange={propsArg.handleColumnNameChange}
                    checked={propsArg.handleColumnName}
                >
                    DX View
                </LinkServiceToggle>
            ),
            // Advanced sorting is not supported for line cutsheets since they have
            // significantly fewer rows there is less value
            ...propsArg.cutsheetType !== ConstantsLightHouse.ISP_CUTSHEET_TYPE.LINE && (
                <LinkServiceSelect
                    selectedAdvancedSortOptions={selectedAdvancedSortOptions}
                    onChange={({ detail }) => {
                        setSelectedAdvancedSortOptions(detail.selectedOption);
                        handleAdvancedSortChange(detail.selectedOption);
                    }}
                    options={IspCutsheetTableData.ADVANCED_SORTING_OPTIONS}
                    placeholder="Advanced Sort"
                />
            ),
            <LinkServiceMultiSelect
                selectedOptions={selectedLiuOptions}
                onChange={({ detail }) => {
                    // Identify required columns
                    const requiredColumns = propsArg.hopOptionsDropDown.filter(
                        (column) => {
                            const idPortAValue = getIdPortAValue(column);
                            return sortedTableItems.some(item => item[idPortAValue]);
                        }
                    );

                    // Combine the required columns with the newly selected options
                    const updatedSelectedOptions = Array.from(
                        new Set([...requiredColumns, ...detail.selectedOptions])
                    );
                    setSelectedLiuOptions(updatedSelectedOptions);
                    propsArg.addRemoveLiuColumns(updatedSelectedOptions);
                }}
                options={propsArg.hopOptionsDropDown}
                hideTokens
                placeholder="Choose options"
                tokenLimit={2}
            />,
            ...propsArg.updatedColumnDefinitions &&
            propsArg.updatedColumnDefinitions.some(e =>
                e.id === ConstantsLightHouse.ISP_ATTRIBUTES.a_osp_panel_location) &&
                <LinkServiceButton
                    variant="primary"
                    iconName={propsArg.isOspLocationEditDisabled ? "lock-private" : "unlocked"}
                    isTracked
                    trackingId="edit-osp-panel-location"
                    onClick={propsArg.handleOnClickEditOSPLocation}
                >
                Edit OSP Panel Location
                </LinkServiceButton>,
            <LinkServiceButton
                variant="primary"
                loading={propsArg.submissionInProgress}
                disabled={propsArg.submissionInProgress}
                isTracked
                trackingId="submit-updates"
                onClick={propsArg.submitUpdates}
            >
                Submit Updates
            </LinkServiceButton>
        ];
        const defaultHeaders = [
            <LinkServiceButton
                variant="primary"
                iconName="download"
                iconAlign="left"
                onClick={() =>
                    HelperFunctions.downloadTableAsCutsheet(
                        sortedTableItems,
                        HelperFunctions.getIspTableDownloadableColumnDefinitions(
                            preferences.contentDisplay, propsArg.updatedColumnDefinitions
                        ),
                        propsArg.searchTerm
                    )}
                loading={false}
                disabled={false}
            >
                Download All Records
            </LinkServiceButton>
        ];
        return (
            <SpaceBetween
                direction="horizontal"
                size={Constants.COMPONENT_CONSTANTS.SPACE_BETWEEN_BUTTON_PADDING}
            >
                {[...propsArg.isCablingCutsheet ? cutsheetHeaders : nonCutsheetHeaders, ...defaultHeaders]}
            </SpaceBetween>
        );
    };

    return (
        <div>
            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_BUTTON_PADDING}>
                <Table
                    {...collectionProps}
                    columnDefinitions={props.updatedColumnDefinitions}
                    columnDisplay={preferences.contentDisplay}
                    items={items}
                    selectionType="multi"
                    onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                    onSortingChange={onSortingChange}
                    sortingColumn={sortingColumn}
                    sortingDescending={sortingDescending}
                    selectedItems={selectedItems}
                    allowFreeTextFiltering
                    loading={props.isSearchInProgress}
                    loadingText="Loading resources"
                    stickyHeader
                    trackBy={identifierProperty}
                    stickyColumns={preferences.stickyColumns}
                    pagination={
                        <Pagination
                            {...paginationProps}
                            // openEnd={canFetchMore(props.nextToken)}
                            ariaLabels={{
                                nextPageLabel: "Next page",
                                previousPageLabel: "Previous page",
                                pageLabel
                            }}
                        />}
                    empty={
                        <Box
                            margin={{ vertical: "xs" }}
                            textAlign="center"
                            color="inherit"
                        >
                            <SpaceBetween size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                                <b>No resources</b>
                            </SpaceBetween>
                        </Box>
                    }
                    filter={
                        <TextFilter
                            filteringText={filteringText}
                            filteringPlaceholder="Find instances"
                            filteringAriaLabel="Filter instances"
                            onChange={({ detail }) =>
                                setFilteringText(detail.filteringText)
                            }
                        />
                    }
                    contentDensity="comfortable"
                    submitEdit={handleSubmitEdit}
                    header={
                        <Header
                            variant="h4"
                            actions={generateTableHeaders(props)}
                        >
                            {`${LighthouseHelperFunctions.toCapitalCamelCase(props.cutsheetType)} Cutsheet ${props.cutsheetRecordNum}
                            (${selectedItems.length} Selected)`}
                        </Header>
                    }
                    preferences={
                        <Preferences
                            preferences={preferences}
                            setPreferences={setPreferences}
                            contentDisplayPreference={contentDisplayPreference}
                            entity={props.cutsheetType}
                        />}
                />
            </SpaceBetween>
        </div>);
});
IspCutsheetTableV2.displayName = "IspCutsheetTableV2";
export default IspCutsheetTableV2;