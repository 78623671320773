import HelperFunctions from "../common/HelperFunctions";
import MangoHelperFunctions from "../../../mango/js/common/HelperFunctions";
import LinkHierarchyHelper from "../../../mango/js/link/LinkHierarchyHelper";
import Constants from "../../../mango/js/utils/Constants";
import LighthouseConstants from "../utils/Constants";

export default class OspDashboardHelper {
    static PANEL_FIELDS = ["a_osp_panel_location", "z_osp_panel_location"];
    static A_SIDE_VALIDATION_FIELDS = ["a_osp_panel_location", "a_osp_panel_ports"];
    static Z_SIDE_VALIDATION_FIELDS = ["z_osp_panel_location", "z_osp_panel_ports"];

    static FIBER_PATH_FIELD = "fiber_path";
    static A_OSP_PANEL_FIELD = "a_osp_panel_location";
    static Z_OSP_PANEL_FIELD = "z_osp_panel_location";

    // Since customer may search between a site where we have a large number of records, we only
    // allow the request to paginate 5 times before we return all results to the customer
    static MAXIMUM_NUMBER_OF_SEARCH_CALLS = 5;
    // https://lucene.apache.org/core/6_5_1/queryparser/org/apache/lucene/queryparser/classic/package-summary.html#Escaping_Special_Characters
    static SPECIAL_CHARS = [".", "*", "+", "\\-", "&", "|", "!", "(", ")", "{", "}", "[", "\\]", "^", "\"", "~",
        "?", ":", "/"];

    // This rule basically allows us to modify the props of the arguments, but not reassign the input args
    /* eslint no-param-reassign: ["error", { "props": false }] */

    static validateRows = (rows) => {
        OspDashboardHelper.#validateRows(rows);
    }

    /**
     * Method to process the rows and find or create links for that given row if OSP information is present.
     * @param linkServiceBackendClient client
     * @param updatedStates updated rows to process
     * @param originalStates original rows to process
     * @param panelLocationWithPortsToLinkMap map that contains OSP panelLocations with ports mapped to P2P link object
     * @returns {Promise<*[]>} list of rows with errors
     */
    static processRows = async (linkServiceBackendClient, updatedStates, originalStates,
                                panelLocationWithPortsToLinkMap) => {
        // Basic check on input to ensure something has changed. If nothing has changed, we have nothing to update
        const changedRows = OspDashboardHelper.#filterChangedRows(updatedStates, originalStates);
        if (HelperFunctions.isArrayEmpty(changedRows.updatedStates)) {
            return [];
        }

        // Get all the unique patch panel information. We need to search on all the rows
        const panelsForSearch = OspDashboardHelper.#findAllUniquePatchPanels(changedRows);

        // Execute all the searches for the patch panels and get back the fibre information
        const fibreLinks = await OspDashboardHelper.#searchForPanels(linkServiceBackendClient, panelsForSearch);

        // Map with existing records, create/update, and return error rows if any (in which case nothing is persisted)
        await OspDashboardHelper.#processOspFibreLinks(linkServiceBackendClient, changedRows, fibreLinks,
            panelLocationWithPortsToLinkMap);

        // If we're all good, there were no errors so we return an empty list of error rows
        return [];
    }

    static #filterChangedRows = (updatedStates, originalStates) => {
        // Changed rows is a list of lists, where the first index is a list of updated rows, and the second index is a
        // list of original rows
        const changedRows = { updatedStates: [], originalStates: [] };

        // Check if we have an empty input
        if (HelperFunctions.isArrayEmpty(updatedStates) || HelperFunctions.isArrayEmpty(originalStates)) {
            return changedRows;
        }

        // The assumption we're making here is that the updatedState and the originalState is in the same order in their
        // respective lists.
        updatedStates.forEach((updatedState, i) => {
            const originalState = originalStates[i];
            const updatedStateIdentifier = OspDashboardHelper.#getRowOspFibreIdentifier(updatedState);
            const originalStateIdentifier = OspDashboardHelper.#getRowOspFibreIdentifier(originalState);

            if (updatedStateIdentifier !== originalStateIdentifier) {
                changedRows.updatedStates.push(updatedState);
                changedRows.originalStates.push(originalState);
            }
        });

        return changedRows;
    }

    static #validateRows = (rows) => {
        const allOspIdentifiersSet = new Set();
        const fiberPathByPatchPanelsMap = new Map(); // This map has a string as key, string as value
        rows.forEach((row) => {
            if (OspDashboardHelper.#isEmptyRow(row)) {
                row.validation_error = undefined;
                return;
            }

            // At this point, we need to clear the validation error so if customers are retrying to fix issues, they can
            // do that. If we don't clear the error, they will remain.
            row.validation_error = undefined;

            // Ensure we have all the necessary fields (A-side is always needed)
            if (!OspDashboardHelper.#isValidRow(row, OspDashboardHelper.A_SIDE_VALIDATION_FIELDS)) {
                row.validation_error = `All fields are required: ${OspDashboardHelper.A_SIDE_VALIDATION_FIELDS}`;
                return; // No point in checking anything any further for this row
            }

            // Ensure that we have all the fields or none of the fields on the Z-side
            if (!OspDashboardHelper.#isValidRow(row, OspDashboardHelper.Z_SIDE_VALIDATION_FIELDS)) {
                row.validation_error = `If adding Z-side OSP information, ensure both fields are populated: ${OspDashboardHelper.Z_SIDE_VALIDATION_FIELDS}`;
                return; // No point in checking anything any further for this row
            }

            // Ensure that the panel information is unique for the A/Z side
            if (!OspDashboardHelper.#hasUniquePanelInformation(row)) {
                row.validation_error = `Cannot have duplicate panel information for OSP on A/Z side. The following fields must be different: ${OspDashboardHelper.PANEL_FIELDS}`;
            }

            // We also want to ensure rows only use a OSP record once
            const ospIdentifier = OspDashboardHelper.#getRowOspFibreIdentifier(row);
            if (ospIdentifier) {
                if (allOspIdentifiersSet.has(ospIdentifier)) {
                    row.validation_error = `OSP Fibre ${ospIdentifier} is already used in a previous row`;
                }
                allOspIdentifiersSet.add(ospIdentifier);
            }

            // We're assuming the row is non-empty at this point. So now if the fiber_path field is populated, we need
            // to group by the patch panel and see if any panel is using multiple paths
            const fiberPath = row[OspDashboardHelper.FIBER_PATH_FIELD];
            if (fiberPath) {
                const aPatchPanel = row[OspDashboardHelper.A_OSP_PANEL_FIELD];
                const zPatchPanel = row[OspDashboardHelper.Z_OSP_PANEL_FIELD];

                // Check the panel on both sides and ensure that there are no repeated fiber paths for a given panel.
                // If there are, add an error to that row
                OspDashboardHelper.#groupFiberPathByPatchPanel(fiberPathByPatchPanelsMap, row, aPatchPanel, fiberPath);
                OspDashboardHelper.#groupFiberPathByPatchPanel(fiberPathByPatchPanelsMap, row, zPatchPanel, fiberPath);
            }
        });
    }

    static #searchForPanels = async (linkServiceBackendClient, panelsForSearch) => {
        const searchString = OspDashboardHelper.#generateSearchStringsForPanels(panelsForSearch);
        const fibreLinks = await linkServiceBackendClient.searchLinks(searchString);
        return fibreLinks.Links;
    }

    static #findAllUniquePatchPanels = (changedRows) => {
        // Ensure that we get all the patch panel fields, even for the original rows because for deconsumption we need
        // the PassiveToPassive link to find its parent
        const patchPanels = changedRows.updatedStates
            .flatMap(row => OspDashboardHelper.PANEL_FIELDS.map(field => row[field]))
            .filter(item => !!item);
        patchPanels.push(...changedRows.originalStates
            .flatMap(row => OspDashboardHelper.PANEL_FIELDS.map(field => row[field]))
            .filter(item => !!item));

        return Array.from(new Set(patchPanels));
    }

    static escapeAllSpecialCharacter = (inputString) => {
        const pattern = new RegExp(`[${OspDashboardHelper.SPECIAL_CHARS.join("")}]`, "g");
        return inputString.replace(pattern, "\\\\$&");
    }

    static #generateSearchStringsForPanels = panelsForSearch =>
        // TODO make sure we can handle larger number of search string, in which case return might be a list;
        `end:/${panelsForSearch.map(panel =>
            `${OspDashboardHelper.escapeAllSpecialCharacter(panel, OspDashboardHelper.SPECIAL_CHARS)}.*`).join("|")}/`

    static #isValidRow = (row, validationFields) => {
        const mappedPanelFields = validationFields.map(field => row[field]);

        // Check to see if all the panelFields are populated or all the panelFields are empty
        if (mappedPanelFields.every(fieldValue => !!fieldValue) || mappedPanelFields.every(fieldValue => !fieldValue)) {
            return true;
        }

        return false;
    }

    static #isEmptyRow = (row) => {
        const mappedPanelFields = OspDashboardHelper.A_SIDE_VALIDATION_FIELDS
            .concat(OspDashboardHelper.Z_SIDE_VALIDATION_FIELDS)
            .map(field => row[field]);

        if (mappedPanelFields.every(fieldValue => !fieldValue)) {
            return true;
        }

        return false;
    }

    static #hasBothSidePanelInfo= (row) => {
        const mappedPanelFields = OspDashboardHelper.A_SIDE_VALIDATION_FIELDS
            .concat(OspDashboardHelper.Z_SIDE_VALIDATION_FIELDS)
            .map(field => row[field]);
        return !!mappedPanelFields.every(fieldValue => !!fieldValue);
    }

    static #hasUniquePanelInformation = (row) => {
        const panelFields = OspDashboardHelper.PANEL_FIELDS.map(field => row[field]);

        if (panelFields.length === new Set(panelFields).size) {
            return true;
        }

        return false;
    }

    static #processOspFibreLinks = async (linkServiceBackendClient, changedRows, links,
                                          panelLocationWithPortsToLinkMap) => {
        // Convert the links into a map where the key is the OSP fibre information so we only traverse it once
        const linksMap = new Map();
        links.forEach((link) => {
            const aEndHandoff = HelperFunctions.getAttributeValueFromLink(link, "a_end_dark_fibre_handoff");
            const zEndHandoff = HelperFunctions.getAttributeValueFromLink(link, "b_end_dark_fibre_handoff");

            // Add each individial side. Then also the combined sides that is agnostic of the order
            OspDashboardHelper.#addToMap(linksMap, aEndHandoff, link);
            OspDashboardHelper.#addToMap(linksMap, zEndHandoff, link);
            OspDashboardHelper.#addToMap(linksMap, `${aEndHandoff}_${zEndHandoff}`, link);
            OspDashboardHelper.#addToMap(linksMap, `${zEndHandoff}_${aEndHandoff}`, link);
        });

        // If we have any OSP identifier for which there are multiple links, throw an exception
        const ospIdentifiersWithDuplicateLinks = Array.from(linksMap.entries())
            .filter(entry => entry[1].length > 1)
            .map(entry => entry[0]);
        if (ospIdentifiersWithDuplicateLinks.length > 0) {
            throw new Error(
                `Found multiple links for the following OSP information: ${ospIdentifiersWithDuplicateLinks}`
            );
        }

        const batchPutLinks = [];
        changedRows.updatedStates.forEach((updatedState, i) => {
            const originalState = changedRows.originalStates[i];

            const updatedStateOspIdentifier = OspDashboardHelper.#getRowOspFibreIdentifier(updatedState);
            const originalStateOspIdentifier = OspDashboardHelper.#getRowOspFibreIdentifier(originalState);
            let keyForPanelLocationWithPortsToLinkMap = null;
            if (OspDashboardHelper.#hasBothSidePanelInfo(updatedState)) {
                keyForPanelLocationWithPortsToLinkMap = OspDashboardHelper
                    .constructKeyForPanelLocationWithPortsToLinkMap(
                        updatedState[LighthouseConstants.ISP_ATTRIBUTES.a_osp_panel_location],
                        updatedState[LighthouseConstants.ISP_ATTRIBUTES.z_osp_panel_location],
                        updatedState[LighthouseConstants.ISP_ATTRIBUTES.a_osp_panel_ports],
                        updatedState[LighthouseConstants.ISP_ATTRIBUTES.z_osp_panel_ports]
                    );
            }

            if (updatedStateOspIdentifier) {
                if (keyForPanelLocationWithPortsToLinkMap
                    && panelLocationWithPortsToLinkMap
                    && panelLocationWithPortsToLinkMap.has(keyForPanelLocationWithPortsToLinkMap)) {
                    // This is the case where we have copied the rows from the search OSP panel location results
                    // and have not edited any values related to OSP panel locations columns
                    updatedState.OspLink =
                        panelLocationWithPortsToLinkMap.get(keyForPanelLocationWithPortsToLinkMap);
                    updatedState.OspIdentifier = updatedStateOspIdentifier;
                    batchPutLinks.push(OspDashboardHelper.#createAddConsumptionPut(updatedState));
                } else if (linksMap.has(updatedStateOspIdentifier) &&
                    linksMap.get(updatedStateOspIdentifier).length === 1) {
                    // Use-case 1: Use existing PassiveToPassive links
                    updatedState.OspIdentifier = updatedStateOspIdentifier;
                    updatedState.OspLink = linksMap.get(updatedStateOspIdentifier).find(Boolean);
                    batchPutLinks.push(OspDashboardHelper.#createAddConsumptionPut(updatedState));
                } else {
                    // Use-case 2: Create new PassiveToPassive link(s) with the right consumption
                    if (!OspDashboardHelper.#getZSideOspFibreIdentifier(updatedState)) {
                        throw new Error(
                            `Cannot create OSP record without Z side for: ${OspDashboardHelper.#getASideOspFibreIdentifier(updatedState)}`
                        );
                    }
                    batchPutLinks.push(OspDashboardHelper.#createNewPassiveToPassiveConsumptionPut(updatedState));
                }
            }

            // Use-case 3: deconsume existing PassiveToPassive link(s) if the existing state has no fiber
            // This also applies to Use-case 1 and 2, where if there is any existing fiber, we need to remove it
            OspDashboardHelper.#deconsumePassiveToPassive(batchPutLinks, linksMap, originalStateOspIdentifier);
        });

        if (batchPutLinks.length > 0) {
            const chunks = MangoHelperFunctions.chunkifyList(
                batchPutLinks,
                LighthouseConstants.MAX_BATCH_PUT_LINKS_CHUNK_SIZE
            );
            await Promise.all(chunks.map(chunk => linkServiceBackendClient.batchPutLinks(chunk)));
        }
    }

    static #deconsumePassiveToPassive = (batchPutLinks, linksMap, identifier) => {
        if (identifier) {
            // If we cannot find the fibre record, we cannot deconsume
            if (!linksMap.has(identifier)
                || linksMap.get(identifier).length !== 1) {
                throw new Error(
                    `Could not find PassiveToPassive record for de-consuming: ${identifier}`
                );
            }

            const fibreRecordToRemoveFromConsumption = linksMap.get(identifier).find(Boolean);
            batchPutLinks.push(OspDashboardHelper.#createRemoveConsumptionPut(fibreRecordToRemoveFromConsumption));
        }
    }

    static #createAddConsumptionPut = (row) => {
        // OSP link is basically the consumedlink in our Put here
        const instanceId = HelperFunctions.getFullLinkInstanceIdFromLink(row.OspLink);
        // consumer is EncryptionToEncryption link, if present, otherwise RouterToRouter link
        const consumerLinkId = HelperFunctions.createFullLinkInstanceId(
            row.encryption_to_encryption_link_instance_id ? row.encryption_to_encryption_link_instance_id :
                row.router_to_router_link_instance_id
        );

        return OspDashboardHelper.#createReplaceConsumersPut(instanceId, [consumerLinkId]);
    }

    static #createRemoveConsumptionPut = (link) => {
        const instanceId = HelperFunctions.getFullLinkInstanceIdFromLink(link);
        const consumerLinkIds = HelperFunctions.getConsumerIdsFromAttribute(link);
        if (consumerLinkIds.length !== 1) {
            throw new Error(
                `Expected a single consumer for PassiveToPassive record ${instanceId}. Found: [${consumerLinkIds}]`
            );
        }

        // We will be replacing the the existing consumers list with an entirely new list
        return OspDashboardHelper.#createReplaceConsumersPut(instanceId, []);
    }

    static #createNewPassiveToPassiveConsumptionPut = (row) => {
        const typeId = LinkHierarchyHelper.getTypeIdFromLink(Constants.LINK_TYPES.passiveToPassive);
        // consumer is EncryptionToEncryption link, if present, otherwise RouterToRouter link
        const consumerLinkId = HelperFunctions.createFullLinkInstanceId(
            row.encryption_to_encryption_link_instance_id ? row.encryption_to_encryption_link_instance_id :
                row.router_to_router_link_instance_id
        );
        const aSideIdentifier = OspDashboardHelper.#getASideOspFibreIdentifier(row);
        const zSideIdentifier = OspDashboardHelper.#getZSideOspFibreIdentifier(row);

        return {
            typeId,
            attributes: [], // TODO see if we can remove this from the backend
            endNames: HelperFunctions.alphanumericSortList([aSideIdentifier, zSideIdentifier]),
            lifecycleState: "OPERATIONAL", // Default to OPERATIONAL
            consumptionAttributes: {
                ReplaceConsumerLinks: [consumerLinkId]
            }
        };
    }

    static #createReplaceConsumersPut = (instanceId, consumerListToReplaceWith) => ({
        instanceId,
        consumptionAttributes: {
            ReplaceConsumerLinks: consumerListToReplaceWith
        }
    })

    static #groupFiberPathByPatchPanel = (map, row, panel, fiberPath) => {
        // If we do not have the panel field, we don't need to do anything further
        if (panel) {
            // If we don't have the patch panel entry, add it with the fibre path information
            if (!map.has(panel)) {
                map.set(panel, fiberPath);
            }

            // Check to see we have a new fiber_path for this patch panel
            if (map.get(panel) !== fiberPath) {
                row.validation_error = `OSP Fibre Patch Panel ${panel} cannot use fiber path ${fiberPath} because it is already using fiber path ${map.get(panel)}`;
            }
        }
    }

    static #addToMap(map, key, value) {
        if (key) {
            if (!map.has(key)) {
                map.set(key, []);
            }

            map.get(key).push(value);
        }
    }

    static #getRowOspFibreIdentifier = (row) => {
        const aSideIdentifier = OspDashboardHelper.#getASideOspFibreIdentifier(row);
        const zSideIdentifier = OspDashboardHelper.#getZSideOspFibreIdentifier(row);

        // Optionally, we could have the Z side information as well
        if (zSideIdentifier) {
            return `${aSideIdentifier}_${zSideIdentifier}`;
        }

        return aSideIdentifier;
    }

    static #getASideOspFibreIdentifier = row =>
        OspDashboardHelper.#getIdentifier(row, "a_osp_panel_location", "a_osp_panel_ports")

    static #getZSideOspFibreIdentifier = row =>
        OspDashboardHelper.#getIdentifier(row, "z_osp_panel_location", "z_osp_panel_ports")

    static #getIdentifier = (row, panelField, portField) => {
        const panel = row[panelField];
        const port = row[portField];

        if (!panel || !port) {
            return null;
        }

        return `${panel}_${port}`;
    }

    static #isSearchParamPresentInAttribute = (searchParm, styxAttribute) => {
        if (!styxAttribute || !searchParm) return false;
        return searchParm.includes(styxAttribute) || styxAttribute.includes(searchParm);
    }

    static #filterLinkBasedOnSearchParams = (links, searchParams) => {
        const filteredLinks = [];
        links.forEach((link) => {
            let shouldAddToFilteredLinks = true;
            Object.values(searchParams).forEach((searchParam) => {
                const aSideStyxAttributeValue =
                    HelperFunctions.getAttributeValueFromLink(link,
                        HelperFunctions.strFormat(searchParam.styxName, "a"));
                const zSideStyxAttributeValue =
                    HelperFunctions.getAttributeValueFromLink(link,
                        HelperFunctions.strFormat(searchParam.styxName, "z"));

                // We already know that the styx site code matches since that is what the backend search
                // already searched on
                if (searchParam.styxName === "styx_%s_site_code") { return; }

                // If the search param provided does not match the attribute value in styx we
                // will not display it to customers. We search on both the A and Z side styx attributes because
                // it is possible that they attribute the customer searched for is present on either side
                if (!OspDashboardHelper.#isSearchParamPresentInAttribute(searchParam.value, aSideStyxAttributeValue) &&
                    !OspDashboardHelper.#isSearchParamPresentInAttribute(searchParam.value, zSideStyxAttributeValue)) {
                    shouldAddToFilteredLinks = false;
                }
            });
            if (shouldAddToFilteredLinks) {
                filteredLinks.push(link);
            }
        });

        return filteredLinks;
    }

    static #getOSPPanelLocationName = (siteCode, roomId, rackId, panelType, panelId) =>
        `${siteCode}_${roomId}_${rackId}_${panelType}_${panelId}`

    static #getPanelLocationInformationFromLink = (link) => {
        const aSiteCode = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_a_site_code);
        const aRoomId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_a_room_id);
        const aRackId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_a_rack_id);
        const aPanelId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_a_panel_id);
        const aPanelType = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_a_panel_type);
        const aPortId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_a_port_id);
        const aPanelLocation =
            OspDashboardHelper.#getOSPPanelLocationName(aSiteCode, aRoomId, aRackId, aPanelType, aPanelId);

        const zSiteCode = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_z_site_code);
        const zRoomId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_z_room_id);
        const zRackId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_z_rack_id);
        const zPanelId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_z_panel_id);
        const zPanelType = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_z_panel_type);
        const zPortId = HelperFunctions.getAttributeValueFromLink(link, Constants.ATTRIBUTES.styx_z_port_id);
        const zPanelLocation =
            OspDashboardHelper.#getOSPPanelLocationName(zSiteCode, zRoomId, zRackId, zPanelType, zPanelId);

        return {
            aPanelLocation,
            aPortId,
            zPanelLocation,
            zPortId
        };
    }

    static #comparePorts = (a, b) => {
        const aPort = a.split("-")[0];
        const bPort = b.split("-")[0];
        return aPort - bPort;
    }

    static constructKeyForPanelLocationWithPortsToLinkMap =
        (aOspPanelLocation, zOspPanelLocation, aPortId, zPortId) =>
            `${aOspPanelLocation}#${zOspPanelLocation}#${aPortId}#${zPortId}`

    static getOspPanelLocationInfo = async (linkServiceBackendClient, searchTerms) => {
        let searchKey = "";
        let response = {};
        if (!searchTerms.aSiteCode || !searchTerms.aSiteCode.value ||
            !searchTerms.zSiteCode || !searchTerms.zSiteCode.value) {
            throw new Error("Site codes are mandatory for OSP Panel location search");
        }

        const aSiteNameInStyxFormat = MangoHelperFunctions.getSiteNameFromPort(searchTerms.aSiteCode.value);
        const zSiteNameInStyxFormat = MangoHelperFunctions.getSiteNameFromPort(searchTerms.zSiteCode.value);
        searchKey = `ospsite:${aSiteNameInStyxFormat} AND ospsite:${zSiteNameInStyxFormat} AND lifecycle:AVAILABLE`;

        let links = [];
        let nextToken = null;
        let numCalls = 0;
        do {
            // By default, lint does not allow using await in a loop. In this case, getAllProviderInfo returns a
            // token that is used for the next iteration which means we have to use await within a loop. For
            // lint to pass, we have to disable that error before that line, which is done below:
            // eslint-disable-next-line no-await-in-loop
            response = await linkServiceBackendClient.searchLinks(searchKey, nextToken, true);
            // Increment the number of calls to ensure we do not recurse infinitely
            numCalls += 1;
            // Add the new links fetched to the list of links
            links = links.concat(response.Links);
            // Update the next token
            nextToken = response.NextToken;
        } while (!!nextToken && numCalls < OspDashboardHelper.MAXIMUM_NUMBER_OF_SEARCH_CALLS);

        // Map to store a panel location as key and a list of ports as value
        const panelLocationToASidePortMap = new Map();
        const panelLocationToZSidePortMap = new Map();
        const panelLocationWithPortsToLinkMap = new Map();
        const ospPanelLocationToBeReturned = [];
        if (links.length > 0) {
            const filteredLinks = OspDashboardHelper.#filterLinkBasedOnSearchParams(links, searchTerms);
            filteredLinks.forEach((link) => {
                const {
                    aPanelLocation, aPortId, zPanelLocation, zPortId
                }
                    = OspDashboardHelper.#getPanelLocationInformationFromLink(link);
                OspDashboardHelper.#addToMap(panelLocationToASidePortMap, `${aPanelLocation}#${zPanelLocation}`, aPortId);
                OspDashboardHelper.#addToMap(panelLocationToZSidePortMap, `${aPanelLocation}#${zPanelLocation}`, zPortId);
                panelLocationWithPortsToLinkMap.set(
                    OspDashboardHelper.constructKeyForPanelLocationWithPortsToLinkMap(aPanelLocation, zPanelLocation,
                        aPortId, zPortId), link
                );
            });
        }

        panelLocationToASidePortMap.forEach((ports, key) => {
            const locations = key.split("#");
            const aPanelLocation = locations[0];
            const zPanelLocation = locations[1];
            const aAvailablePorts = ports;
            const zAvailablePorts = panelLocationToZSidePortMap.get(key);

            aAvailablePorts.sort(OspDashboardHelper.#comparePorts);
            zAvailablePorts.sort(OspDashboardHelper.#comparePorts);

            ospPanelLocationToBeReturned.push({
                aOspPanelLocation: aPanelLocation,
                zOspPanelLocation: zPanelLocation,
                aAvailablePorts,
                zAvailablePorts
            });
        });
        return {
            ospPanelLocation: ospPanelLocationToBeReturned,
            panelLocationWithPortToLinkMap: panelLocationWithPortsToLinkMap
        };
    }

    static mergeSortedContinuousPorts = (ports) => {
        // Convert single-digit ports to ranges
        const portRanges = ports.map((port) => {
            if (!port.includes("-")) {
                return `${port}-${port}`;
            }
            return port;
        });

        const mergedPorts = [];
        let currentMin = null;
        let currentMax = null;

        for (let i = 0; i < portRanges.length; i += 1) {
            const portRange = portRanges[i];
            const [min, max] = portRange.split("-").map(Number);

            if (currentMin === null) {
                // First port range
                currentMin = min;
                currentMax = max;
            } else if (min <= currentMax + 1) {
                // Contiguous port range, update the max
                currentMax = Math.max(currentMax, max);
            } else {
                // Non-contiguous port range, add the previous range and start a new one
                if (currentMin === currentMax) {
                    // Single-digit port, add it as is
                    mergedPorts.push(`${currentMin}`);
                } else {
                    mergedPorts.push(`${currentMin}-${currentMax}`);
                }
                currentMin = min;
                currentMax = max;
            }
        }

        // Add the last range
        if (currentMin !== null) {
            if (currentMin === currentMax) {
                mergedPorts.push(`${currentMin}`);
            } else {
                mergedPorts.push(`${currentMin}-${currentMax}`);
            }
        }
        return mergedPorts.join(", ");
    }
}